import { render, staticRenderFns } from "./Slack.vue?vue&type=template&id=7da27393&scoped=true"
import script from "./Slack.vue?vue&type=script&lang=js"
export * from "./Slack.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7da27393",
  null
  
)

export default component.exports