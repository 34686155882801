<template />

<script>
export default {
  name: 'Slack',
}
</script>

<style scoped>

</style>
